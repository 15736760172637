import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Container = styled.a`
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
  background-image: url("${props => props.image}");
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100%;
  transition: background-size 0.2s ease;
  will-change: transform, opacity;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  &:hover,
  &:focus {
    background-size: 115%;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 35, 35, 0.75);
`;

const Title = styled.h2`
  font-size: 1.75rem;
  font-weight: inherit;
  color: #ffffff;
  padding: 1rem;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;

  & > span {
    color: ${props => props.theme.colors.primary};
  }
`;

const BottomPanel = styled.div`
  width: 100%;
  height: 35%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Paragraph = styled.p`
  margin: 0;
`;

const ShowMoreText = styled.p`
  font-size: 1rem;
  margin: 0;

  & > span {
    color: ${props => props.theme.colors.primary};
  }
`;

const Card = ({ link, title, children, image }) => {
  return (
    <Container as={Link} to={link} image={image}>
      <Content>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <BottomPanel>
          <Paragraph>{children}</Paragraph>
          <ShowMoreText>
            Dowiedz się <span>więcej...</span>
          </ShowMoreText>
        </BottomPanel>
      </Content>
    </Container>
  );
};

export default Card;
