import React from "react";
import styled from "styled-components";

const Container = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  padding-bottom: 3.5rem;
  margin-bottom: 2rem;
  will-change: transform, opacity;
`;

const IconContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.invertColors ? "#ffffff" : props.theme.colors.primary};
  box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.25);
`;

const Icon = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: ${props =>
    props.invertColors ? props.theme.colors.primary : "#ffffff"};
  stroke: ${props =>
    props.invertColors ? props.theme.colors.primary : "#ffffff"};

  svg {
    max-height: 60%;
    width: 100%;
  }
`;

const Title = styled.h3`
  text-align: center;
  font-size: 1.125rem;
  font-weight: inherit;
  margin: 2.25rem 0 1.5rem 0;
`;

const Paragraph = styled.p`
  margin: 0 auto;
  text-align: center;
  max-width: 75%;
`;

const Feature = ({ children, icon, title, invertColors }) => {
  return (
    <Container>
      <IconContainer invertColors={invertColors}>
        <Icon invertColors={invertColors}>{icon}</Icon>
      </IconContainer>
      <Title>{title}</Title>
      <Paragraph>{children}</Paragraph>
    </Container>
  );
};

export default Feature;
