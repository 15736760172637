import React, { useEffect, useRef } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled from "styled-components";
import { gsap } from "gsap";

import heroBackgroundImage from "../images/hero.jpg";
import alternateHeroBackgroundImage from "../images/heroAlternate.jpg";

const Container = styled.section`
  width: 100%;
  height: 100vh;
  padding: 0 !important;
  background-image: url("${props =>
    props.useAlternateImage
      ? alternateHeroBackgroundImage
      : heroBackgroundImage}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  max-width: none;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(38, 38, 38, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #ffffff;
  font-weight: inherit;
  text-align: center;
  line-height: 1.75;
`;

const Button = styled.a`
  color: #ffffff;
  border: 1px solid #ffffff;
  text-align: center;
  width: 100%;
  height: 50px;
  max-width: 360px;
  margin-top: 5rem;
  background-color: transparent;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ease;

  &:hover,
  &:focus {
    background-color: #ffffff;
    color: #000000;
  }
`;

const Hero = ({
  title,
  buttonText,
  buttonLink,
  useAlternateImage = false,
  ...props
}) => {
  const content = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      content.current.children,
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 2, stagger: 1 }
    );
  }, []);

  return (
    <Container useAlternateImage={useAlternateImage} {...props}>
      <Content ref={content}>
        <Title>{title}</Title>
        <Button as={AnchorLink} to={buttonLink}>
          {buttonText}
        </Button>
      </Content>
    </Container>
  );
};

export default Hero;
