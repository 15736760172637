import React from "react";
import styled from "styled-components";
import Icon from "../../components/icon";

const Svg = styled(Icon)``;

const Car = ({ className }) => {
  return (
    <Svg viewBox="0 0 512 273" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.512,213.558c0.471-1.516,0.93-3.035,1.415-4.547
		c1.339-4.171,3.896-6.947,8.854-7.353c0-1.775-0.005-3.534,0.001-5.294c0.035-9.39,0.779-18.675,2.708-27.916
		c2.001-9.585,7.835-14.771,16.933-17.361c13.389-3.813,27.014-6.508,40.801-7.959c6.287-0.661,9.365-4.649,12.798-8.576
		c11.058-12.644,21.684-25.675,33.007-38.072c5.423-5.938,11.798-11.1,18.184-16.041c6.969-5.392,15.555-6.825,24.065-7.595
		c8.053-0.729,16.169-1.013,24.259-1.027c50.679-0.087,101.359-0.044,152.039-0.044c1.012,0,2.022,0,3.432,0
		c0,1.421-0.014,2.658,0.002,3.895c0.063,5.029-0.302,10.109,0.292,15.075c1.257,10.512,8.298,16.711,18.845,17.243
		c0.511,0.026,1.054-0.098,1.529,0.035c0.745,0.208,1.446,0.577,2.166,0.877c-0.305,0.642-0.492,1.384-0.937,1.908
		c-1.431,1.688-3.086,3.197-4.422,4.953c-5.258,6.907-4.737,16.996,1.252,23.266c4.3,4.5,8.551,9.083,13.204,13.198
		c7.397,6.541,18.45,5.963,25.528-0.944c0.488-0.477,0.9-1.067,1.464-1.421c0.582-0.365,1.453-0.851,1.926-0.647
		c0.479,0.205,0.826,1.171,0.88,1.832c0.205,2.544-0.001,5.146,0.438,7.641c1.255,7.157,7.658,14.235,17.09,14.703
		c6.456,0.32,12.95,0.082,19.418-0.154c4.846-0.178,8.752-2.578,12-6.077c0.326-0.351,0.461-0.879,0.685-1.325
		c0.352,0.152,0.704,0.304,1.057,0.455c0,7.55,0.046,15.099-0.015,22.647c-0.05,6.225-0.085,12.455-0.384,18.671
		c-0.331,6.879-0.493,13.816-1.577,20.593c-1.128,7.047-6.095,11.211-12.977,12.418c-7.786,1.366-15.676,2.147-23.526,3.138
		c-5.57,0.701-11.15,1.328-17.017,2.022c0-1.061-0.083-2.037,0.013-2.996c1.104-11.065,0.717-22.019-3.382-32.489
		c-6.028-15.397-17.215-24.964-33.532-27.946c-17.266-3.155-32.661,1.21-45.721,12.961c-8.959,8.062-13.843,18.365-14.258,30.396
		c-0.195,5.643,0.878,11.33,1.374,16.997c0.051,0.588,0.085,1.179,0.139,1.949c-56.951,0-113.808,0-170.461,0
		c0.495-5.277,1.383-10.5,1.407-15.729c0.112-24.675-16.72-42.16-39.365-46.576c-14.929-2.912-28.876-0.526-41.09,9.012
		c-8.717,6.806-13.544,16.03-15.697,26.75c-1.665,8.289-1.443,16.603-0.224,24.923c0.072,0.489,0.052,0.992,0.089,1.797
		c-3.495,0-6.885,0-10.275,0c-3.669,0-7.345-0.148-11.003,0.048c-2.885,0.155-4.927-1.042-6.592-3.165
		c-3.243-4.134-4.696-9.019-5.811-14.041c-0.325-1.463-0.684-2.919-1.027-4.378C0.512,220.044,0.512,216.801,0.512,213.558z
		 M175.39,88.148c-6.683,0-13.09,0.277-19.464-0.065c-9.542-0.511-17.172,3.479-24.169,9.319
		c-11.69,9.759-20.653,21.72-27.88,35.011c-2.471,4.544-4.729,9.258-6.51,14.104c-1.328,3.613-0.303,4.53,3.614,4.53
		c23.892,0.004,47.784,0.003,71.675-0.003c0.897-0.001,1.795-0.082,2.733-0.127C175.39,129.914,175.39,109.208,175.39,88.148z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M512.512,95.798c-2.598,6.873-7.652,9.586-14.816,8.83
		c-1.351-0.143-2.746,0.119-4.09-0.053c-2.047-0.262-3.05,0.528-3.719,2.476c-0.798,2.324-2.119,4.467-2.979,6.775
		c-0.271,0.728-0.086,1.992,0.407,2.571c1.93,2.268,4.233,4.226,6.091,6.545c3.474,4.339,3.148,10.455-0.712,14.482
		c-3.777,3.941-7.638,7.809-11.585,11.579c-4.501,4.298-10.536,4.474-15.262,0.443c-2.331-1.987-4.462-4.219-6.601-6.42
		c-0.943-0.971-1.72-1.313-3.089-0.681c-2.391,1.104-4.877,2.038-7.392,2.825c-1.43,0.448-1.814,1.191-1.77,2.583
		c0.082,2.557,0.085,5.122-0.028,7.677c-0.253,5.665-4.877,10.366-10.555,10.515c-5.882,0.153-11.774,0.16-17.656,0.002
		c-5.784-0.155-10.389-4.88-10.644-10.721c-0.127-2.896-0.105-5.803-0.022-8.7c0.042-1.459-0.324-2.263-1.829-2.831
		c-2.937-1.109-5.754-2.533-9-3.998c-1.72,1.824-3.476,3.793-5.345,5.646c-5.488,5.441-12.177,5.435-17.616,0.008
		c-3.443-3.434-6.879-6.877-10.27-10.363c-4.987-5.127-5.048-11.416-0.157-16.62c1.869-1.988,3.945-3.79,5.722-5.853
		c0.549-0.637,0.788-1.962,0.542-2.786c-0.755-2.522-1.897-4.925-2.719-7.431c-0.438-1.335-1.036-1.936-2.518-1.867
		c-2.469,0.116-4.948,0.074-7.42-0.003c-6.059-0.188-10.771-4.875-10.897-10.975c-0.113-5.458-0.096-10.92-0.022-16.379
		c0.09-6.655,4.793-11.347,11.482-11.522c2.813-0.074,5.637,0.091,8.441-0.088c0.768-0.049,1.788-0.788,2.163-1.492
		c1.28-2.4,2.245-4.968,3.493-7.388c0.623-1.208,0.471-1.961-0.511-2.843c-1.585-1.424-3.069-2.962-4.567-4.48
		c-5.177-5.244-5.215-12.054-0.063-17.262c3.42-3.458,6.873-6.882,10.351-10.281c5.345-5.223,11.662-5.229,16.954-0.042
		c1.889,1.852,3.667,3.825,5.659,5.554c0.595,0.517,1.85,0.742,2.619,0.505c2.68-0.825,5.324-1.811,7.881-2.961
		c0.708-0.318,1.45-1.385,1.512-2.161c0.2-2.544,0.024-5.116,0.119-7.672c0.225-6.078,4.93-10.763,11.022-10.881
		c5.202-0.101,10.408-0.052,15.612-0.023c7.287,0.039,11.978,4.59,12.233,11.901c0.092,2.642-0.109,5.299,0.091,7.929
		c0.064,0.842,0.807,1.952,1.556,2.364c2.306,1.271,4.81,2.181,7.148,3.399c1.297,0.676,2.105,0.486,3.092-0.549
		c1.881-1.972,3.815-3.917,5.904-5.661c4.583-3.826,10.51-3.628,14.843,0.519c4.005,3.833,7.935,7.751,11.772,11.752
		c4.369,4.553,4.52,10.394,0.409,15.212c-1.989,2.331-4.185,4.494-6.378,6.638c-1.008,0.984-1.181,1.815-0.589,3.135
		c1.043,2.325,1.925,4.735,2.693,7.167c0.431,1.365,1.064,1.89,2.528,1.833c2.639-0.104,5.303-0.166,7.928,0.07
		c5.604,0.504,8.705,4.09,10.55,9.06C512.512,81.804,512.512,88.801,512.512,95.798z M401.83,82.986
		c-0.046,15.752,12.075,28.065,27.691,28.131c15.522,0.065,28.074-12.321,28.172-27.798c0.097-15.62-12.514-28.387-27.955-28.299
		C414.008,55.109,401.875,67.267,401.83,82.986z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M349.197,273.462c-21.198,0.034-38.389-17.094-38.422-38.283
		c-0.034-21.175,17.127-38.435,38.266-38.484c21.08-0.05,38.463,17.258,38.496,38.332
		C387.571,256.148,370.334,273.428,349.197,273.462z M330.268,234.993c-0.068,10.255,8.521,19.076,18.819,18.926
		c10.5-0.153,18.83-7.829,18.821-18.848c-0.009-10.698-7.888-18.658-18.617-18.761C338.31,216.205,330.646,224.539,330.268,234.993z
		"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.064,235.165c-0.232,21.588-17.527,38.559-39.027,38.293
		c-21.063-0.26-37.904-17.626-37.631-38.806c0.275-21.307,17.556-38.214,38.792-37.954
		C103.108,196.954,120.287,214.405,120.064,235.165z M100.425,235.076c0.146-9.608-7.407-18.692-18.827-18.824
		c-10.395-0.12-18.743,8.576-18.685,18.983c0.059,10.301,8.438,18.69,18.709,18.732C91.975,254.011,100.43,245.515,100.425,235.076z
		"
      />
    </Svg>
  );
};

export default Car;
