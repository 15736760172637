import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import SectionTitle from "./section-title";
import Feature from "./feature";
import Card from "../components/card";

import Car from "../images/svg/car";
import Experience from "../images/svg/experience";
import Complexity from "../images/svg/complexity";

const Grid = styled.div`
  display: block;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.375rem;

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    display: grid;
  }
`;

const Offer = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            slug
            thumbnail {
              childImageSharp {
                original {
                  src
                }
              }
            }
            description
            title
          }
        }
      }
    }
  `);

  const features = useRef(null);
  const cards = useRef(null);

  useEffect(() => {
    gsap.set(features.current.children, { opacity: 0 });
    gsap.set(cards.current.children, { opacity: 0 });

    ScrollTrigger.batch(features.current.children, {
      once: true,
      start: "top 80%",
      onEnter: targets =>
        gsap.fromTo(
          targets,
          {
            y: 50,
            opacity: 0,
            scale: 0.5,
          },
          {
            y: 0,
            opacity: 1,
            scale: 1,
            stagger: 0.7,
            duration: 3,
            ease: "power2.out",
          }
        ),
    });

    ScrollTrigger.batch(cards.current.children, {
      once: true,
      batchMax: 3,
      start: "top 80%",
      onEnter: targets =>
        gsap.fromTo(
          targets,
          { opacity: 0, scale: 0.7, y: 50 },
          {
            opacity: 1,
            scale: 1,
            y: 0,
            stagger: 0.7,
            duration: 2,
            ease: "power2.out",
          }
        ),
    });
  }, []);

  return (
    <section id="oferta">
      <SectionTitle title="Oferta">
        Zapoznaj się z szerokim wachlarzem oferowanych przez nas usług oraz
        udogodnień
      </SectionTitle>
      <Grid ref={features}>
        <Feature title="Dojazd do klienta" icon={<Car />}>
          Usługi wykonywane są zarówno w warsztacie jak i u samego klienta
        </Feature>
        <Feature
          title="Wieloletnie doświadczenie"
          icon={<Experience />}
          invertColors
        >
          Posiadamy wieloletnie doświadczenie poparte wieloma projektami oraz
          certyfikatami
        </Feature>
        <Feature title="Szeroka gama usług" icon={<Complexity />}>
          Zróżnicowane usługi spersonalizowane dla klienta
        </Feature>
      </Grid>
      <Grid ref={cards}>
        {data.allMarkdownRemark.nodes.map(node => (
          <Card
            key={node.frontmatter.slug}
            title={node.frontmatter.title}
            image={node.frontmatter.thumbnail.childImageSharp.original.src}
            link={node.frontmatter.slug}
          >
            {node.frontmatter.description}
          </Card>
        ))}
      </Grid>
    </section>
  );
};

export default Offer;
