import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import Offer from "../components/offer";
import Partners from "../components/partners";
import Contact from "../components/contact";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Hero
        title="Wyspecjalizowane Usługi"
        buttonText="Sprawdź ofertę!"
        buttonLink="/#oferta"
        id="home"
      />
      <Offer />
      <Partners />
      <Contact />
    </Layout>
  );
};

export default IndexPage;
