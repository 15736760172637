import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import SectionTitle from "./section-title";

import McCainLogo from "../images/svg/McCain.svg";
import FarmFritesLogo from "../images/svg/FarmFrites.svg";
import HeinzLogo from "../images/svg/Heinz.svg";

const Section = styled.section`
  width: 100%;
  padding: 0 !important;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 0 15% !important;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    padding: 0 5% !important;
  }
`;

const TitleContainer = styled.div`
  padding: 0 1rem;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.375rem;

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    display: grid;
  }
`;

const Partner = styled.a`
  display: flex;
  background-color: #ffffff;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 2px;

  &:hover,
  &:focus {
    img {
      filter: saturate(100%);
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    height: 190px;
  }
`;

const Logo = styled.img`
  display: block;
  max-height: 60%;
  filter: saturate(0);
  transition: filter 0.2s ease-in-out;
`;

const partners = [
  {
    id: 1,
    name: "McCain",
    link: "http://www.mccain.pl",
    image: McCainLogo,
  },
  {
    id: 2,
    name: "Farm Frites",
    link: "https://www.farmfrites.com",
    image: FarmFritesLogo,
  },
  {
    id: 3,
    name: "Heinz",
    link: "https://www.heinz.pl",
    image: HeinzLogo,
  },
];

const Partners = () => {
  const partnersContainer = useRef(null);

  useEffect(() => {
    gsap.set(partnersContainer.current.children, { opacity: 0 });

    ScrollTrigger.batch(partnersContainer.current.children, {
      once: true,
      start: "top 90%",
      onEnter: targets =>
        gsap.fromTo(
          targets,
          {
            y: 50,
            opacity: 0,
            scale: 0.5,
          },
          {
            y: 0,
            opacity: 1,
            scale: 1,
            stagger: 0.7,
            duration: 2,
            ease: "power2.out",
          }
        ),
    });
  }, []);

  return (
    <Section>
      <TitleContainer>
        <SectionTitle title="Partnerzy">
          Zapoznaj się z firmami, które skorzystały z naszych usług.
        </SectionTitle>
      </TitleContainer>
      <Container ref={partnersContainer}>
        {partners.map(partner => (
          <Partner
            key={partner.id}
            href={partner.link}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <Logo src={partner.image} alt={partner.name} />
          </Partner>
        ))}
      </Container>
    </Section>
  );
};

export default Partners;
