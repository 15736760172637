import React from "react";
import styled from "styled-components";
import Icon from "../../components/icon";

const Svg = styled(Icon)``;

const Complexity = ({ className }) => {
  return (
    <Svg viewBox="0 0 260 260" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M260.06,67.115c-0.726,2.327-1.757,4.497-4.097,5.552
		c-1.226,0.552-2.625,0.856-3.97,0.964c-1.981,0.159-3.986,0.004-5.979,0.076c-0.394,0.014-0.964,0.357-1.128,0.703
		c-1.026,2.145-1.959,4.334-3.025,6.739c1.277,1.252,2.654,2.614,4.045,3.962c2.458,2.381,3.066,5.28,2.113,8.451
		c-0.331,1.102-1.11,2.157-1.919,3.011c-2.411,2.55-4.92,5.011-7.428,7.469c-3.535,3.466-7.764,3.494-11.39,0.082
		c-1.451-1.367-2.81-2.834-4.279-4.179c-0.318-0.292-1.006-0.472-1.403-0.339c-2.01,0.667-3.995,1.421-5.952,2.232
		c-0.352,0.146-0.742,0.696-0.758,1.075c-0.088,1.947-0.001,3.901-0.08,5.85c-0.16,3.862-3.429,7.161-7.309,7.25
		c-4.071,0.094-8.148,0.027-12.222,0.013c-3.285-0.011-7.151-3.066-7.262-7.432c-0.054-2.124-0.044-4.249-0.009-6.373
		c0.011-0.742-0.179-1.132-0.935-1.436c-2.127-0.857-4.197-1.847-6.31-2.792c-1.279,1.231-2.674,2.457-3.93,3.81
		c-1.732,1.866-3.886,2.663-6.346,2.745c-2.271,0.078-4.087-1.023-5.627-2.536c-2.597-2.55-5.148-5.151-7.649-7.797
		c-3.066-3.245-3.024-7.56,0.061-10.806c1.433-1.508,2.909-2.977,4.413-4.413c0.531-0.506,0.572-0.91,0.294-1.577
		c-0.783-1.875-1.509-3.777-2.166-5.701c-0.236-0.689-0.519-0.965-1.263-0.935c-1.473,0.059-2.955-0.077-4.421,0.034
		c-4.577,0.348-8.538-2.98-8.667-7.85c-0.104-3.938,0.043-7.881,0.089-11.822c0.038-3.415,3.082-7.083,7.938-7.176
		c1.949-0.038,3.9-0.038,5.852,0.008c0.75,0.018,1.136-0.194,1.437-0.932c0.75-1.843,1.583-3.655,2.473-5.435
		c0.352-0.702,0.33-1.138-0.261-1.679c-1.183-1.082-2.304-2.232-3.432-3.372c-3.273-3.303-3.514-7.948-0.362-11.32
		c2.722-2.91,5.685-5.591,8.521-8.394c2.295-2.269,7.685-2.344,10.019,0.098c1.526,1.596,3.172,3.077,4.724,4.649
		c0.521,0.528,0.933,0.688,1.683,0.367c1.827-0.783,3.699-1.466,5.581-2.108c0.69-0.235,0.93-0.546,0.932-1.283
		c0.007-2.206-0.07-4.435,0.212-6.612c0.468-3.597,3.606-6.316,7.259-6.409c3.596-0.091,7.202,0.093,10.793-0.075
		c4.712-0.222,8.743,3.087,8.671,8.672c-0.022,1.733,0.026,3.469-0.017,5.201c-0.019,0.748,0.195,1.158,0.936,1.46
		c1.802,0.736,3.583,1.537,5.317,2.42c0.815,0.416,1.308,0.288,1.897-0.354c1.055-1.149,2.225-2.191,3.291-3.329
		c2.917-3.113,8.003-3.392,11.051-0.406c2.939,2.881,5.895,5.754,8.663,8.795c2.499,2.748,2.391,6.947-0.077,9.746
		c-1.518,1.721-3.152,3.34-4.772,4.968c-0.494,0.499-0.597,0.902-0.314,1.587c0.79,1.918,1.523,3.864,2.172,5.834
		c0.246,0.746,0.693,0.732,1.277,0.75c2.111,0.065,4.228,0.091,6.332,0.256c3.479,0.273,5.327,2.508,6.429,5.57
		c0.083,0.231,0.189,0.454,0.285,0.68C260.06,57.924,260.06,62.52,260.06,67.115z M202.688,78.084
		c10.519,0.216,18.695-7.806,19.396-17.613c0.928-12.974-9.281-21.001-18.929-20.99c-10.879,0.013-19.473,8.305-19.559,19.165
		C183.515,69.352,191.934,78.155,202.688,78.084z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.06,17.693c3.553-0.01,7.104-0.029,10.657-0.03
		c21.318-0.002,42.637-0.001,63.954-0.001c0.555,0,1.11,0,1.752,0c0,25.489,0,50.86,0,76.441c-0.538,0-1.082,0-1.626,0
		c-24.351,0-48.702,0-73.053,0.002c-0.563,0-1.123,0.041-1.685,0.063C-0.06,68.676-0.06,43.185-0.06,17.693z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.202,99.949c0.476-0.013,0.952-0.036,1.428-0.036
		c24.354-0.002,48.707-0.001,73.061-0.001c0.51,0,1.021,0,1.615,0c0,25.497,0,50.87,0,76.454c-25.451,0-50.908,0-76.365,0
		c0-25.404,0-50.81,0-76.215C0.027,100.083,0.115,100.016,0.202,99.949z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.202,99.949c-0.087,0.067-0.175,0.134-0.262,0.202
		c0-0.087,0-0.174,0-0.26C0.027,99.91,0.115,99.929,0.202,99.949z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.305,99.912c0.508,0,0.972,0,1.435,0
		c23.146,0,46.293,0.004,69.439-0.018c0.892-0.002,1.469,0.333,2.165,0.861c2.725,2.073,3.401,4.694,3.377,8.085
		c-0.156,21.89-0.08,43.778-0.08,65.668c0,0.557,0,1.114,0,1.765c-25.473,0-50.852,0-76.336,0
		C82.305,150.88,82.305,125.47,82.305,99.912z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.329,258.516c0-25.404,0-50.732,0-76.146
		c25.464,0,50.798,0,76.223,0c0,25.373,0,50.708,0,76.146C133.174,258.516,107.811,258.516,82.329,258.516z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.887,182.391c0,25.399,0,50.729,0,76.144
		c-25.391,0-50.765,0-76.226,0c0-25.373,0-50.705,0-76.144C190.036,182.391,215.397,182.391,240.887,182.391z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.286,17.749c24.013,0,47.912,0,71.909,0
		c-4.929,7.409-4.015,14.121,2.32,20.255c-0.087,0.08-0.173,0.159-0.26,0.239c-1.017,0-2.041,0.077-3.05-0.013
		c-6.156-0.545-12.059,4.764-13.212,10.179c-0.196,0.926-0.362,1.879-0.371,2.822c-0.042,4.117-0.096,8.238,0,12.353
		c0.112,4.896,2.578,8.518,6.564,11.141c2.085,1.373,4.428,2.105,6.975,2.019c0.641-0.022,1.284-0.003,2.271-0.003
		c-0.484,0.545-0.741,0.91-1.07,1.193c-2.942,2.534-4.825,5.702-5.237,9.555c-0.174,1.623,0.257,3.316,0.462,4.972
		c0.063,0.494,0.292,0.966,0.467,1.521c-22.657,0-45.167,0-67.768,0C82.286,68.525,82.286,43.191,82.286,17.749z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.909,176.318c-25.452,0-50.797,0-76.239,0
		c0-22.368,0-44.69,0-67.167c6.743,2.911,12.324,1.028,17.221-3.996c0.131,1.813,0.179,3.518,0.391,5.201
		c0.664,5.292,4.732,9.687,9.686,11.058c1.308,0.362,2.702,0.534,4.061,0.563c3.597,0.076,7.196-0.031,10.791,0.038
		c6.249,0.119,12.223-4.503,13.485-10.303c0.371-1.7,0.432-3.468,0.662-5.441c0.499,0.467,0.949,0.83,1.329,1.256
		c2.588,2.901,5.826,4.643,9.665,5.048c2.533,0.267,4.956-0.486,7.24-1.603c0.54-0.264,1.053-0.581,1.709-0.946
		C240.909,132.162,240.909,154.179,240.909,176.318z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.688,196.957c0.823-0.897,1.698-1.798,2.515-2.75
		c1.392-1.622,4.9-1.604,6.288-0.19c1.728,1.761,3.492,3.487,5.238,5.232c1.519,1.518,1.542,5.02-0.266,6.471
		c-0.066,0.054-0.121,0.124-0.183,0.186c-2.883,2.976-2.896,2.98-1.234,6.745c0.13,0.295,0.589,0.586,0.923,0.622
		c0.813,0.091,1.654,0.119,2.464,0.015c2.623-0.34,5.176,1.608,5.276,4.951c0.064,2.12,0.087,4.249-0.007,6.368
		c-0.135,2.998-2.151,5.051-5.147,4.763c-2.885-0.277-4.596,0.518-5.388,3.402c-0.245,0.896-0.267,1.396,0.417,1.991
		c0.684,0.597,1.287,1.284,1.919,1.937c1.774,1.835,1.426,4.979-0.215,6.566c-1.526,1.476-2.985,3.021-4.515,4.493
		c-2.106,2.028-4.738,2.032-6.821-0.004c-0.989-0.968-1.881-2.031-2.782-3.014c-0.961,0.41-1.663,0.74-2.387,1.015
		c-2.317,0.878-2.38,0.872-2.304,3.383c0.071,2.353-0.425,4.62-2.816,5.305c-3.506,1.003-7.219,0.992-10.732-0.018
		c-1.986-0.57-2.684-2.57-2.843-4.57c-0.102-1.286,0.381-2.874-0.216-3.798c-0.598-0.925-2.212-1.191-3.382-1.747
		c-0.193-0.092-0.389-0.179-0.748-0.344c-0.723,0.769-1.448,1.563-2.199,2.335c-2.308,2.369-5.049,2.467-7.451-0.019
		c-1.386-1.433-2.826-2.813-4.202-4.254c-2.093-2.193-2.118-4.516-0.12-6.798c0.113-0.131,0.22-0.278,0.36-0.372
		c2.386-1.578,2.88-3.521,1.361-6.032c-0.378-0.625-0.6-0.973-1.385-0.985c-1.365-0.021-2.774-0.038-4.083-0.375
		c-2.056-0.529-3.35-2.302-3.403-4.406c-0.059-2.295-0.073-4.594,0.003-6.889c0.091-2.717,1.983-4.447,4.691-4.431
		c0.954,0.005,1.915-0.074,2.858,0.021c1.062,0.106,1.627-0.321,1.885-1.308c0.075-0.29,0.224-0.562,0.345-0.84
		c1.126-2.592,1.127-2.592-0.938-4.648c-2.544-2.533-2.555-5.166-0.024-7.694c1.348-1.348,2.689-2.702,4.061-4.027
		c2.218-2.145,5.017-2.302,6.957,0.062c1.968,2.397,3.907,2.942,6.563,1.372c0.551-0.325,0.904-0.514,0.87-1.199
		c-0.048-0.951-0.028-1.906-0.004-2.859c0.066-2.622,1.988-4.598,4.605-4.654c2.339-0.051,4.681-0.053,7.019,0.007
		c2.581,0.065,4.442,1.99,4.516,4.589c0.033,1.17-0.055,2.346,0.048,3.507c0.036,0.4,0.402,0.919,0.763,1.113
		C48.978,195.794,50.186,196.278,51.688,196.957z M38.226,232.065c6.36,0.034,11.535-4.775,11.586-11.324
		c0.053-6.803-4.583-11.876-11.661-11.842c-7.293,0.035-11.624,5.492-11.576,11.577C26.625,226.998,31.386,232.003,38.226,232.065z"
      />
    </Svg>
  );
};

export default Complexity;
