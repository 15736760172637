import React from "react";
import styled from "styled-components";
import Icon from "../../components/icon";

const Svg = styled(Icon)``;

const Experience = ({ className }) => {
  return (
    <Svg viewBox="0 0 106 104" className={className}>
      <path d="M22.6354 96.3317L29.1828 91.2282L36.6359 94.8537L33.8496 87.0115L39.5775 80.9951L31.3135 81.256L27.3971 73.9094L25.0767 81.9167L16.9248 83.3947L23.7655 88.0896L22.6354 96.3317ZM53 104L56.1314 96.2795L64.3781 95.6623L58.0723 90.2719L60.0477 82.1689L53.0086 86.5594L45.9696 82.1689L47.945 90.2719L41.6219 95.6623L49.8687 96.2795L53 104ZM28.6134 45.6185C32.4435 44.1752 37.3346 42.3321 40.8542 40.1324C44.8913 43.9057 47.5137 48.2006 47.7207 53.4171H58.3138C58.5208 48.2006 61.1432 43.9057 65.1803 40.1324C68.6999 42.3321 73.591 44.1665 77.4211 45.6185C88.7043 49.8699 83.1921 57.6338 76.6102 64.1719C70.5718 70.1622 62.2301 73.8659 53.0086 73.8659C43.7871 73.8659 35.4541 70.1709 29.4071 64.1719C22.9201 57.7295 17.2526 49.896 28.6134 45.6185ZM44.7015 34.829C44.874 33.4031 40.6989 28.004 39.9398 25.4044C38.3008 22.7962 37.7228 18.623 39.5085 15.8582C40.2244 14.7627 39.9139 12.7979 39.9139 11.2938C39.9139 -3.75589 66.0775 -3.77328 66.0775 11.2938C66.0775 13.1978 65.6462 14.6497 66.6727 16.1451C68.3893 18.6491 67.5008 23.0918 66.0516 25.4218C65.1286 28.1431 60.7464 33.2988 61.057 34.8463C61.3158 42.5581 44.6411 42.306 44.7015 34.829ZM106 73.5094L100.936 66.9192L104.534 59.4075L96.744 62.2244L90.7746 56.4514L91.0334 64.7805L83.7442 68.7276L91.689 71.0577L93.1554 79.2737L97.805 72.3792L106 73.5094ZM83.3646 96.3317L82.2518 88.0635L89.0925 83.3687L80.9492 81.882L78.6374 73.8746L74.721 81.2212L66.4484 80.9604L72.1763 86.9768L69.3641 94.8363L76.8172 91.2108L83.3646 96.3317ZM0 73.5007L8.20361 72.3792L12.8618 79.2737L14.3369 71.0664L22.2817 68.7363L14.9925 64.7892L15.2513 56.4601L9.27328 62.2331L1.46647 59.4075L5.06364 66.9192L0 73.5007Z" />
    </Svg>
  );
};

export default Experience;
